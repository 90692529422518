<template>
<div class="my-4">
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Regulators</h1>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface
            v-model="rowsFiltered"
            namespace="regulators"
            :rows="rows"
            :staticDataProperties="staticDataProperties"
            :include-taxonomy="false"
            ></filter-interface>
          <search-interface namespace="regulators" :rows="rowsFiltered" v-model="rowsShow"></search-interface>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading && permissions.read">
      <b-col>
        <b-card class="mb-3">
            <b-button class="mr-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'regulator' }}">add Regulator</b-button>
        </b-card>
        <div>
          <b-card>
            <div class="mb-3" v-for="row in rowsShow" :key="row.id">
              <regulator-simple
                :item="row"
                :username="user.username"
              />
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import RegulatorSimple from '@/components/RegulatorSimple.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'

export default {
  name: 'Regulators',
  components: {
    RegulatorSimple,
    FilterInterface,
    SearchInterface
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Regulators"
    this.$stat.log({ page: 'regulators', action: 'open regulators' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('regulator').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('regulator').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    const data = {
      permissions: {
        add: false,
        read: false
      },
      filter: '',
      loading: true,
      rows: [],
      rowsFiltered: [],
      rowsShow: [],
      staticDataProperties: [
        { name: 'del', property: 'del', direct: true },
        { name: 'keywords', property: 'keywords', direct: true }
      ]
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.rows = await this.$Amplify.API.get('cosmos', '/standard/regulator')
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    resetFilters: function () {
      this.statusSelected = false
    }
  }
}
</script>

<style>
</style>
